import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message as Mesage, Upload, Form, Typography, Space} from 'antd'
import readXlsxFile from 'read-excel-file'
const { Dragger } = Upload;

const MySingleFileUpload = ({label, name, required, message, form, application, prepareExcelData}) => {
    const props = {
        name: 'file',
        multiple: false,
        action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
        onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (status === 'done') {
            form.setFieldsValue({...form.getFieldsValue, [name]: info?.file?.originFileObj || null})
            if(prepareExcelData)
              readXlsxFile(info?.file?.originFileObj).then((rows) => {
                prepareExcelData(rows)
              })
          } else if (status === 'error') {
            form.setFieldsValue({...form.getFieldsValue, [name]: info?.file?.originFileObj || null})
            if(prepareExcelData)
              readXlsxFile(info?.file?.originFileObj).then((rows) => {
                prepareExcelData(rows)
              })
          }
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        }
    }
    return (
        <Form.Item
            name={name}
            label={<Typography.Text strong>{label}</Typography.Text>}
            rules={[
                {
                required,
                message,
                },
            ]}
        >
          <Space direction='vertical' className='w-100'>
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                  <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                  Upload single file
              </p>
            </Dragger>
            {
              application ?
              <Typography.Text className='danger-color'>
                {
                  application
                }
              </Typography.Text>
              :<></>
            }
          </Space>
        </Form.Item>
    )
}
export {MySingleFileUpload}