import React, {lazy, Suspense, useEffect } from 'react'
import { Route, Routes as Switch} from 'react-router-dom'
import {actionsApi, isUnAuthorize} from "../shared";
import { useDispatch, useSelector } from 'react-redux'
import { SyncOutlined } from '@ant-design/icons';
import { Login } from '../pages/Login'
import { Space, Image} from 'antd'
import { useTranslation } from "react-i18next"
const Entry = lazy(() => import('../pages/index.jsx'))

const  Routes = () => {

  const dispatch=useDispatch()
  const {isLogin, loading}= useSelector(state => state?.loginCheck)
  const { i18n } = useTranslation();
  useEffect(()=>{
    if(isUnAuthorize())
      dispatch(actionsApi?.loginCheckResponse(false))
    else
      dispatch(actionsApi?.authCheck())
  },[])
  useEffect(()=>{
    let lang= localStorage.getItem("lang")
    i18n.changeLanguage(lang  || 'en')
    dispatch(actionsApi?.changeLanguage(lang  || 'en'))
}, [])    
  const Fallback = () => (
    <div className='center' style={{height: '100vh'}}>
      <Space direction='vertical' className='align-center'>
          <Image
            style={{ width: '320px'}}
            src= {"./assets/images/logo-t.png"}
            alt='Rental Admin Panel'
            preview={false}
          />
        <SyncOutlined  spin style={{color:'black',fontSize: '35px'}}/>
      </Space>
    </div>
  )
  return (
    <>
      <Suspense>
        {
          loading ?
          <Fallback /> :
          !loading && !isLogin ?
          <Login/> :
          <Switch>
            <Route 
                path="/*" 
                element={
                  // <Protected>
                      <Entry />
                  // </Protected>
                }
            />
          </Switch>
        }
      </Suspense>
    </>
  )
}
export default Routes