import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { message } from 'antd'
import { checkAuthorization } from '../../../helpers'

export const getAllClient = createAsyncThunk('client',
    async (object, { dispatch, getState }) => {

        const { userToken }= checkAuthorization()
        dispatch(getting())
        var myHeaders = new Headers()
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({name: object?.name, order: ""})
        }
        fetch(domainUrl + "/viewAllClients", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.success){
                    dispatch(clientResponse(result?.data))
                    dispatch(setPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                message.error(error)
                dispatch(clientClear()) 
            })
    }
)
export const getClientLookup = createAsyncThunk('client',
    async (object, { dispatch, getState }) => {

        const { userToken }= checkAuthorization()
        var myHeaders = new Headers()
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + "/clientsLookUps", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.success)
                    dispatch(setClientLookup(result?.data))
                else 
                    throw result?.message
            })
            .catch(error => { 
                message.error(error)
                dispatch(setClientLookup([])) 
            })
    }
)

const ClientSlice = createSlice({
    name: 'client',
    initialState: {
        data: [],
        loading: false,
        pagination:{
            pageNo:1,
            pageSize: 20,
            totalRecords:0,
        },
        filters: {
            name: ''
        },
        clientLookup:[]
    },
    reducers: {
        getting: state => {
            state.loading = true
        },
        clientResponse: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        clientClear: state => {
            state.data = []
            state.loading = false
        },
        changeClientFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
        setClientLookup: (state, action)=> {
            state.clientLookup= action?.payload
        }
    }
})

export const { getting, clientResponse, clientClear, changeClientFilters, setPages, setClientLookup} = ClientSlice.actions;
export default ClientSlice.reducer;