import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { message } from 'antd'
import { checkAuthorization } from '../../../helpers'

export const getAllExpenses = createAsyncThunk('expense',
    async (object, { dispatch, getState }) => {

        const { userToken }= checkAuthorization()
        dispatch(getting())
        var myHeaders = new Headers()
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(object?.payload)
        }
        fetch(domainUrl + "/viewAllExpenses", requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.success){
                dispatch(expenseResponse(result?.data))
                dispatch(setPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
            }
            else 
                throw result?.message
        })
        .catch(error => { 
            message.error(error)
            dispatch(expenseClear()) 
        })
    }
)

const ExpenseSlice = createSlice({
    name: 'expense',
    initialState: {
        data: [],
        loading: false,
        pagination:{
            pageNo:1,
            pageSize: 20,
            totalRecords:0
        },
        filters: {
            propertyAddedFor: "2",
            clientID: null,
            propertyID: null,
            year: null,
            month: null
        }
    },
    reducers: {
        getting: state => {
            state.loading = true
        },
        expenseResponse: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        expenseClear: state => {
            state.data = []
            state.loading = false
        },
        changeExpenseFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        }
    }
})

export const { getting, expenseResponse, expenseClear, changeExpenseFilters, setPages} = ExpenseSlice.actions;
export default ExpenseSlice.reducer;