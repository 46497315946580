import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { message } from 'antd'
import { checkAuthorization } from '../../../helpers'

export const getReport = createAsyncThunk('report',
    async (object, { dispatch, getState }) => {

        const { userToken }= checkAuthorization()
        dispatch(getting())
        var myHeaders = new Headers()
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(object?.payload)
        }
        fetch(domainUrl + "/viewReport", requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.success)
                dispatch(reportResponse([result]))
            else 
                throw result?.message
        })
        .catch(error => { 
            message.error(error)
            dispatch(reportResponse([])) 
        })
    }
)
export const getAllBookingsForReport = createAsyncThunk('report',
    async (object, { dispatch, getState }) => {

        const { userToken }= checkAuthorization()
        dispatch(gettingBooking())
        var myHeaders = new Headers()
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(object?.payload)
        }
        fetch(domainUrl + "/viewAllBookingsNajla", requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.success){
                dispatch(bookingResponse(result?.data))
                dispatch(setBookingPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
            }
            else 
                throw result?.message
        })
        .catch(error => { 
            message.error(error)
            dispatch(bookingResponse([])) 
        })
    }
)
export const getAllExpenseForReport = createAsyncThunk('expense',
    async (object, { dispatch, getState }) => {

        const { userToken }= checkAuthorization()
        dispatch(gettingExpense())
        var myHeaders = new Headers()
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(object?.payload)
        }
        fetch(domainUrl + "/viewAllExpensesNajla", requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.success){
                dispatch(expenseResponse(result?.data))
                dispatch(setExpensePages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
            }
            else 
                throw result?.message
        })
        .catch(error => { 
            message.error(error)
            dispatch(expenseResponse([])) 
        })
    }
)
export const getAllRentForReport = createAsyncThunk('expense',
    async (object, { dispatch, getState }) => {

        const { userToken }= checkAuthorization()
        dispatch(gettingRent())
        var myHeaders = new Headers()
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(object?.payload)
        }
        fetch(domainUrl + "/viewAllRents", requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.success){
                dispatch(rentResponse(result?.data))
                dispatch(setExpensePages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
            }
            else 
                throw result?.message
        })
        .catch(error => { 
            message.error(error)
            dispatch(rentResponse([])) 
        })
    }
)

const ReportSlice = createSlice({
    name: 'report',
    initialState: {
        data: [],
        loading: false,
        booking: [],
        loadingBooking: false,
        bookingPagination:{
            pageNo:1,
            pageSize: 20,
            totalRecords:0
        },
        expense: [],
        loadingExpense: false,
        expensePagination:{
            pageNo:1,
            pageSize: 20,
            totalRecords:0
        },
        rent: [],
        loadingRent: false,
        rentPagination:{
            pageNo:1,
            pageSize: 20,
            totalRecords:0
        },
    },
    reducers: {
        getting: state => {
            state.loading = true
        },
        reportResponse: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        gettingBooking: state => {
            state.loadingBooking = true
        },
        bookingResponse: (state, action) => {
            state.booking = action.payload
            state.loadingBooking = false
        },
        setBookingPages:(state, action)=>{
            state.bookingPagination= action.payload
        },
        gettingExpense: state => {
            state.loadingExpense = true
        },
        expenseResponse: (state, action) => {
            state.expense = action.payload
            state.loadingExpense = false
        },
        setExpensePages:(state, action)=>{
            state.expensePagination= action.payload
        },
        gettingRent: state => {
            state.loadingRent = true
        },
        rentResponse: (state, action) => {
            state.rent = action.payload
            state.loadingRent = false
        },
        setRentPages:(state, action)=>{
            state.rentPagination= action.payload
        }
    }
})

export const { 
    getting, reportResponse, reportClear,
    gettingBooking, bookingResponse, setBookingPages,
    gettingExpense, expenseResponse, setExpensePages,
    gettingRent, rentResponse, setRentPages
} = ReportSlice.actions;
export default ReportSlice.reducer;