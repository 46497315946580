import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography, Divider} from "antd"
import { MyInput, MySelect, MySingleFileUpload } from "../Forms"
import { apiCalls } from "../../shared/Apis";
import { PhoneOutlined } from "@ant-design/icons";

const { Title } = Typography;

const DataEntryDrawer = ({visible, onClose, editemp}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && editemp) {
            form.setFieldsValue({
                name: editemp?.name,
                email: editemp?.email,
                phoneNo: editemp?.phoneNo,
                password: editemp?.password,
                userName: editemp?.userName,
            })
        } else {
            form.resetFields();
        }
    }, [visible, editemp])

    const DataEntryRegister= async()=>{
        setLoading(true)
        let data= form.getFieldsValue()
        let result= await apiCalls?.dataentry?.DataEntryRegister(data)
        if(result){
            closeDrawer()
        }
        setLoading(false)
    }

    const closeDrawer= ()=>{
        form.resetFields()
        onClose()
    }

  return (
    <Drawer
        title={editemp?'Edit employee':'Register Data Entry'}
        onClose={closeDrawer}
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {editemp? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            onFinish={DataEntryRegister}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-400">Data Entry DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Name'
                        name='name'
                        placeholder='e.g John Doe'
                        size='large'
                        required
                        message='Please enter name'
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='User Name'
                        name='userName'
                        placeholder='e.g jhon'
                        size='large'
                        required
                        message='Please enter user name'
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Email address'
                        name='email'
                        placeholder='e.g ali@gmail.com'
                        size='large'
                        required
                        message='Please enter email name'
                    />
                </Col>     
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Password'
                        name='password'
                        type='password'
                        size='large'
                        required={!editemp}
                        message='Please enter password'
                    />
                </Col>  
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Phone Number'
                        name='phoneNo'
                        placeholder='e.g +966 12345689'
                        size='large'
                        required
                        message='Please enter phone number'
                        addonAfter={<PhoneOutlined/>}
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {DataEntryDrawer}