import { message } from "antd";
import { checkAuthorization } from "../../helpers";
import { domainUrl } from "../../constants";

const AddHost = (data) => {
    const {userToken} = checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Authorization', userToken);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(data),
        redirect: 'follow'
    };
    const url = data?.id ? '/hostUpdate' : '/hostRegister'
    return(
        fetch(domainUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message);
                return 1
            } 
            else 
                throw result?.message
            
        })
        .catch(error => {
            message.error(error || 'Something went wrong');
            return 0 
        })
    )
};

const deleteHost= (Id)=>{
    const {userToken} = checkAuthorization();
    var myHeaders = new Headers()
    myHeaders.append('Authorization', userToken)
    var requestOptions = {
      method:'DELETE',
      headers: myHeaders,
      redirect:'follow'
    }
    return(
        fetch(domainUrl + `/deleteHost/${Id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
            return 0
        })
    )
  }

const hostapi = {
    AddHost,
    deleteHost,
}

export { hostapi }