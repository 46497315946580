import { PlusOutlined } from "@ant-design/icons"
import {Space, Button, Typography} from "antd"

export const ModuleTopHeading=({name, onClick})=>{
    return (
        <Space  style={{alignItems:'center'}}>
            <Typography.Title level={4} className="my-0 fw-500">
                {
                    name
                }
            </Typography.Title>
            {
                onClick ?
                <Button                             
                    type="primary" 
                    shape="circle" 
                    size='small' 
                    style={{color:'var(--black-color)',backgroundColor:'var(--secondary-color)'}}
                    icon={<PlusOutlined/>}
                    onClick={onClick}
                />
                :<></>
            }
        </Space>
    )
}