import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { message } from 'antd'
import { checkAuthorization } from '../../../helpers'

export const getDashboardStats = createAsyncThunk('stats',
    async (_, { dispatch, getState }) => {

        const { userToken }= checkAuthorization()
        dispatch(getting())
        var myHeaders = new Headers()
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }
        fetch(domainUrl + "/dashboardStats", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.success)
                    dispatch(dashboardStatsResponse(result))
                else 
                    throw result?.message
            })
            .catch(error => { 
                message.error(error)
                dispatch(dashboardStatsResponse(null)) 
            })
    }
)
export const getComparisonGraph = createAsyncThunk('comparisonGraph',
    async (_, { dispatch, getState }) => {

        const { userToken }= checkAuthorization()
        var myHeaders = new Headers()
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + "/comparisonGraph", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.success)
                    dispatch(setComparisonGraph(result?.data))
                else 
                    throw result?.message
            })
            .catch(error => { 
                message.error(error)
                dispatch(setComparisonGraph([])) 
            })
    }
)

const DashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        stats: null,
        loading: false,
        comparisonGraph:[]
    },
    reducers: {
        getting: state => {
            state.loading = true
        },
        dashboardStatsResponse: (state, action) => {
            state.stats = action.payload
            state.loading = false
        },
        setComparisonGraph: (state, action)=> {
            state.comparisonGraph= action?.payload
        }
    }
})

export const { getting, dashboardStatsResponse, setComparisonGraph} = DashboardSlice.actions;
export default DashboardSlice.reducer;