import { message } from "antd";
import { checkAuthorization } from "../../helpers";
import { domainUrl } from "../../constants";

const addUpdateClient = (formData) => {

    const {userToken} = checkAuthorization()
    var myHeaders = new Headers()
    myHeaders.append('Authorization', userToken)

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formData,
        redirect: 'follow'
    };
    let url = formData.get("id") ? '/updateClient' :'/storeClient'
    return (
        fetch(domainUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error || 'Something went wrong');
            return 0
        })
    )
}


const deleteClient=(clientID)=>{
    const {userToken} = checkAuthorization();
    var myHeaders = new Headers()
    myHeaders.append('Authorization', userToken)
    var requestOptions = {
      method:'DELETE',
      headers: myHeaders,
      redirect:'follow'
    }
    return(
        fetch(domainUrl + `/deleteClient/${clientID}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message)
                return 1
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error || 'Something went wrong');
            return 0
        })
    )
  }

const client = {
    addUpdateClient,
    deleteClient
}

export {client}