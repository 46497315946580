import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Upload, Typography, Image, message, Divider} from "antd"
import { MyInput, MySelect, MySingleFileUpload } from "../../Forms"
import { propertyCity } from "../../../shared/lookups";
import { apiCalls } from "../../../shared/Apis";
import { PhoneOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;
const { Dragger } = Upload;

const AddEditEmpDrawer = ({visible, onClose, editemp, searchHandler}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && editemp) {
            form.setFieldsValue({
                name: editemp?.name,
                email: editemp?.email,
                phoneNo: editemp?.phoneNo,
                password: editemp?.password,
                nationality: editemp?.nationality,
                city: editemp?.city,
                address: editemp?.address,
            })
        } else {
            form.resetFields();
        }
    }, [visible, editemp])

    const addUpdateEmployee= async()=>{
        setLoading(true)
        let data= form.getFieldsValue()
        const formdata = new FormData()
        if(editemp?.id)
            formdata.append("id", editemp?.id)
        formdata.append("name", data?.name)
        formdata.append("email", data?.email)
        formdata.append("phoneNo", data?.phoneNo)
        formdata.append("password", data?.password)
        formdata.append("nationality", data?.nationality)
        formdata.append("city", data?.city)
        formdata.append("address", data?.address)
        if(data?.documents)
            formdata.append("documents", data?.documents)
        let result= await apiCalls?.employee?.addUpdateEmployee(formdata)
        if(result){
            searchHandler('')
            closeDrawer()
        }
        setLoading(false)
    }

    const closeDrawer= ()=>{
        form.resetFields()
        onClose()
    }

  return (
    <Drawer
        title={editemp?'Edit employee':'Add employee'}
        onClose={closeDrawer}
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {editemp? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            onFinish={addUpdateEmployee}
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mt-0 mb-3 fw-400">EMPLOYEE DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Employee Name'
                        name='name'
                        placeholder='e.g John Doe'
                        size='large'
                        required
                        message='Please enter employee name'
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Phone Number'
                        name='phoneNo'
                        placeholder='e.g +966 12345689'
                        size='large'
                        required
                        message='Please enter phone number'
                        addonAfter={<PhoneOutlined/>}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Email address'
                        name='email'
                        placeholder='e.g ali@gmail.com'
                        size='large'
                        required
                        message='Please enter email name'
                    />
                </Col>     
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label='Password'
                        name='password'
                        type='password'
                        size='large'
                        required={!editemp}
                        message='Please enter password'
                    />
                </Col>  
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'City'
                        name="city" 
                        placeholder='e.g Tabook'
                        size='large'
                        required
                        message='Please choose city'
                        options={propertyCity}
                    />
                </Col>              
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Nationality'
                        name="nationality" 
                        placeholder='e.g saudi'
                        size='large'
                        required
                        message='Please choose nationality'
                        options={[
                            {
                                key: 1,
                                name: 'Saudi Arab'
                            },
                            {
                                key: 2,
                                name: 'Australia'
                            }
                        ]}
                    />
                </Col>
                <Col span={24}>
                    <MyInput
                        label='Residential Address'
                        name='address'
                        placeholder='e.g House 12, Blue Valley'
                        size='large'
                        required
                        message='Please enter residential address'
                    />
                </Col>
                <Col span={24}>
                    <Divider>
                        <Title level={5} className="my-0">DOCUMENTS</Title>
                    </Divider>
                </Col>
                <Col span={24}>
                    <MySingleFileUpload
                        name='documents'
                        label='Owner identity card'
                        required={!editemp}
                        message='please upload owner identity card'
                        form={form}
                        application={editemp?.document || null}
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditEmpDrawer}