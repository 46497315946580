import { message } from "antd";
import { checkAuthorization } from "../../helpers";
import { domainUrl } from "../../constants";

const profilepost = (formData) => {
    const {userToken} = checkAuthorization();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", userToken);

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(formData),
        redirect: "follow"
    };
    return(
        fetch(domainUrl + '/updateProfile', requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success(result?.message);
                return 1
            } 
            else 
                throw result?.message
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
        })
    )
};


const ProfileApi = {
    profilepost
}

export { ProfileApi }