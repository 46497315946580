import { Modal, Space, Typography, Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons';
import { onlyYearMonth } from '../../shared';
const { Title, Text } = Typography

const AlertModal = ({visible, onClose, callBack, deletionLoading, booking}) => {

  return (
    <div>
        <Modal width={500} className='shadow-c'  open={visible} onOk={onClose} onCancel={onClose} centered footer={null}>
            <Space className='w-100 py-2 text-center' align='center' size={10} direction='vertical'>
                <DeleteOutlined style={{fontSize: '40px'}} />
                <Title level={4} className='my-0'>Alert</Title>
                <Text className='text-center fs-13'>Are you sure you want to permanently delete <strong>"{visible?.name || ''}"</strong> {booking && " booking."} {visible?.month && <Text className='danger-color'>{onlyYearMonth(visible?.month)}</Text>}</Text>               
                <Space style={{justifyContent:"end",marginTop:'15px'}} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        danger
                        loading={deletionLoading}
                        className='btnsave'
                        onClick={callBack}
                    >
                        Confirm
                    </Button>
                </Space>
            </Space>
        </Modal>
    </div>
  )
}

export {AlertModal}