
//login
import { login, forgotPassword, loginResponse, loginClear, AuthCheck, refreshToken } from "./action/Login"
import { authCheck, loginCheckResponse} from "./action/Login/loginCheck"
//dashboard
import { getAddBoxDetail, changeAddboxDetailFilters } from "./action/addandbox"
import { getAddVendorDetail, changeAddvendorDetailFilters } from "./action/mastervendor"
//client
import { getAllClient, changeClientFilters, getClientLookup} from "./action/client"
//property
import { getAllProperties, changePropertyFilters, getAllPropertyImages, getPropertyLookup, getPropertyByClientLookup, setPropertyByClientLookup} from "./action/property"
//booking
import { getAllBookings, changeBookingFilters } from "./action/booking"
//expense
import { getAllExpenses } from "./action/expense"
//report 
import { getReport, getAllBookingsForReport, getAllExpenseForReport, getAllRentForReport} from "./action/report"
//emp
import { getAllEmployee, changeEmployeeFilters } from "./action/employee"
//app
import { changeLanguage } from "./action/app"
//dashboard
import { getDashboardStats, getComparisonGraph} from "./action/dashboard"
import { getAllHost, changeHostFilters } from "./action/host"
export const actionsApi = {
    login, 
    forgotPassword,
    loginResponse, 
    loginClear, 
    authCheck, 
    loginCheckResponse,
    refreshToken,
    //addandroidbox 
    // getBoxStatistics,
    getAddBoxDetail, 
    changeAddboxDetailFilters,
    // addmastervendor
    getAddVendorDetail,
    changeAddvendorDetailFilters,
    //client
    getAllClient,
    changeClientFilters,
    getClientLookup,
    //property
    getAllProperties, 
    changePropertyFilters,
    getAllPropertyImages,
    getPropertyLookup,
    getPropertyByClientLookup,
    setPropertyByClientLookup,
    //booking
    getAllBookings, 
    changeBookingFilters,
    //expense
    getAllExpenses,
    //report
    getReport, 
    getAllBookingsForReport,
    getAllExpenseForReport,
    getAllRentForReport,
    //app
    changeLanguage,
    //dashboard
    getDashboardStats,
    getComparisonGraph,
    // employee
    getAllEmployee, 
    changeEmployeeFilters,
    // host
    getAllHost, 
    changeHostFilters,
}
