import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { message } from 'antd'
import { checkAuthorization } from '../../../helpers'

export const getAllProperties = createAsyncThunk('property',
    async (object, { dispatch, getState }) => {

        const { userToken }= checkAuthorization()
        dispatch(getting())
        var myHeaders = new Headers()
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({name: object?.name, propertyAddedFor: object?.propertyAddedFor})
        }
        fetch(domainUrl + "/viewPropertiesNajla", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.success){
                    dispatch(propertyResponse(result?.data))
                    dispatch(setPages({pageNo: object?.pageNo, pageSize: object?.pageSize, totalRecords: result?.totalRecords}))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                message.error(error)
                dispatch(propertyClear()) 
            })
    }
)
export const getAllPropertyImages = createAsyncThunk('propertyImages',
    async (propertyID, { dispatch, getState }) => {

        const { userToken }= checkAuthorization()
        var myHeaders = new Headers()
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + "/viewPropertyImages/"+propertyID, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.success){
                    dispatch(propertyImagesResponse(result?.data))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                message.error(error)
                dispatch(propertyImagesResponse([])) 
            })
    }
)
export const getPropertyLookup = createAsyncThunk('propertyImages',
    async (_, { dispatch, getState }) => {

        const { userToken }= checkAuthorization()
        var myHeaders = new Headers()
        myHeaders.append("Authorization", userToken)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + "/propertiesLookUps", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.success){
                    dispatch(setPropertyLookup(result?.data))
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                message.error(error)
                dispatch(setPropertyLookup([])) 
            })
    }
)
export const getPropertyByClientLookup = createAsyncThunk('propertyByClient',
    async (object, { dispatch, getState }) => {

        const { userToken }= checkAuthorization()
        var myHeaders = new Headers()
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(object),
            redirect: 'follow'
        }
        fetch(domainUrl + "/viewClientPropertiesLookUps", requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.success){
                dispatch(setPropertyByClientLookup(result?.data))
            }
            else 
                throw result?.message
        })
        .catch(error => { 
            message.error(error)
            dispatch(setPropertyByClientLookup([])) 
        })
    }
)
const PropertySlice = createSlice({
    name: 'property',
    initialState: {
        data: [],
        loading: false,
        pagination:{
            pageNo:1,
            pageSize: 20,
            totalRecords:0,
        },
        filters: {
            name: ''
        },
        images:[],
        propertyLookup: [],
        propertyByClientLookup: []
    },
    reducers: {
        getting: state => {
            state.loading = true
        },
        propertyResponse: (state, action) => {
            state.data = action.payload
            state.loading = false
        },
        propertyClear: state => {
            state.data = []
            state.loading = false
        },
        changePropertyFilters: (state, action) => {
            state.filters = action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
        propertyImagesResponse: (state, action)=>{
            state.images= [...action.payload]
        },
        setPropertyLookup: (state, action)=> {
            state.propertyLookup= action?.payload
        },
        setPropertyByClientLookup: (state, action)=> {
            state.propertyByClientLookup= action?.payload
        }
    }
})

export const { getting, propertyResponse, propertyClear, changePropertyFilters, setPages, propertyImagesResponse, setPropertyLookup, setPropertyByClientLookup} = PropertySlice.actions;
export default PropertySlice.reducer;